import './src/assets/styles/global.scss'
import browserHydrateFunction from './src/utils/browser-hydrate-function'

const transitionDelay = 500

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}

export const onRouteUpdate = () => {
  if (window.location.hostname === 'localhost') return

  navigator.serviceWorker.register('/sw.js').then((reg) => {
    reg.update()
  })
}

export const replaceHydrateFunction = browserHydrateFunction
